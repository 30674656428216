<template>
  <div class="redUnionDetail">
    <div class="list-content">
      <div class="item-l"><img :src="postData.picture" alt="" /></div>
      <div class="item-r">
        <div class="title">{{ postData.allianceName || "" }}</div>
        <div class="h3">负责人：{{ postData.contact || "" }}</div>
        <div class="h4">联系电话：{{ postData.contactPhone || "" }}</div>
      </div>
    </div>
    <div class="main">
      <v-h5Mtml :content="postData.content"></v-h5Mtml>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getCpbAllianceInfoByIdURL } from "./api.js";
var moment = require("moment");
export default {
  name: "redUnionDetail",
  data() {
    return {
      id: "",
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo(id) {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getCpbAllianceInfoByIdURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.redUnionDetail {
  min-height: 100vh;
  padding: 18px 32px;
  box-sizing: border-box;
  .title {
    font-weight: 700;
    font-size: 48px;
    color: #000000;
    line-height: 66px;
  }
  .time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 18px 0 30px;
    .time-l {
      font-weight: 400;
      font-size: 24px;
      color: #aaaaaa;
      line-height: 34px;
    }
    .left-wrap {
      display: flex;
      align-items: center;
      .iconTag {
        flex: 1;
        overflow: hidden;
        margin-right: 8px;
        display: flex;
        align-items: center;
        > span {
          height: 32px;
          display: flex;
          align-items: center;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          border-radius: 4px;
          font-size: 24px;
          background: #fff3eb;
          border-radius: 8px;
          border: 2px solid #ffc7a4;
          padding: 0px 8px;
          color: #fe6f16;
        }
      }
      .iconText {
        display: flex;
        color: #a8a8a8;
        align-items: center;
        &:not(:last-child) {
          margin-right: 32px;
        }
        .icon {
          display: flex;
          align-items: center;
          &.icon-up {
            > img {
              margin-top: 4px;
            }
          }
          > img {
            width: 36px;
            height: 36px;
          }
        }
        .text {
          margin-left: 8px;
          display: flex;
          align-items: center;
          height: 36px;
          line-height: 36px;
          span {
            &.hasPraised {
              color: #ff853a;
            }
            font-size: 24px;
            line-height: 1;
          }
        }
      }
    }
  }
  .list-content {
    display: flex;
    justify-items: center;
    height: 248px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 32px 16px 30px 18px;
    position: relative;
    .item-l {
      width: 248px;
      height: 186px;
      flex-shrink: 0;
      margin-right: 18px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 17px;
      }
    }
    .item-r {
      flex: 1;
      .title {
        width: 284px;
        font-weight: 700;
        font-size: 35px;
        color: #1a1c34;
        line-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .h3 {
        font-weight: 400;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
        margin: 30px 0;
      }
      .h4 {
        font-weight: 400;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
      }
    }
    .detail {
      width: 82px;
      position: absolute;
      top: 42px;
      right: 0;
      display: flex;
      align-items: center;
      .detail-l {
        font-weight: 500;
        font-size: 24px;
        color: #fb8133;
        line-height: 34px;
      }
      .detail-r {
        width: 28px;
        height: 28px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
