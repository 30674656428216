// 话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
// 查询话题详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 分页查询党建联盟表
const getCpbAllianceInfoPageURL = `/gateway/hc-serve/cpbAlliance/getCpbAllianceInfoPage`;
// 根据ID获取党建联盟表
const getCpbAllianceInfoByIdURL = `/gateway/hc-serve/cpbAlliance/getCpbAllianceInfoById`;
export {
  getTopicListURL,
  getCpbAllianceInfoByIdURL,
  getCpbAllianceInfoPageURL,
  getTopicInfoByIdURL,
};
